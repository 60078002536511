import React from 'react';
import logo from './logo.svg';
import './App.css';
import Chatguessr from './Chatguessr';

function App() {
  return (
    <div className='App'>
      <Chatguessr />
    </div>
  );
}


export default App;
